import { Controller } from '@hotwired/stimulus';

/**
 * Updates parts of a form when the update function is called.
 *
 * @example
 * %form(data-controller="updating-form" data-updating-form-url="/some/url")
 *    %select(data-action="change->updating-form#update" data-updating-form-url="/some/url")
 *    %input(data-action="change->updating-form#update" data-updating-form-url="/some/other/url")
 */
export default class extends Controller {
  connect() {
    this.element.addEventListener('update', this.handleUpdate);

    // Normally this would be handled by event handlers in jquery-ujs, but in this case we have
    // a form with data-turbo="true", and a submit button with data-turbo="false", so we have to handle
    // enabling/disabling the form ourselves.
    $(this.element).on('submit.rails', this.handleSubmit);
    $(this.element).on('ajax:send.rails', this.handleAjaxSend);
    $(this.element).on('ajax:complete.rails', this.handleAjaxComplete);
  }

  disconnect() {
    this.element.removeEventListener('update', this.handleUpdate);
    $(this.element).off('submit.rails', this.handleSubmit);
    $(this.element).off('ajax:send.rails', this.handleAjaxSend);
    $(this.element).off('ajax:complete.rails', this.handleAjaxComplete);
  }

  handleSubmit = () => {
    // See https://github.com/rails/jquery-ujs/blob/83b041ba72261f487b9264b9e94211e8107a1f18/src/rails.js#L479
    // for explanation about the setTimeout.
    setTimeout(() => $.rails.disableFormElements($(this.element)), 13);
  };

  handleAjaxSend = () => {
    $.rails.disableFormElements($(this.element));
  };

  handleAjaxComplete = () => {
    $.rails.enableFormElements($(this.element));
  };

  handleUpdate = (e) => {
    this.update(e);
  };

  update(e) {
    const url = e.currentTarget.dataset.updatingFormUrl || this.data.get('url') || window.location.href;
    fetch(url, {
      body: new FormData(this.element),
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
        Accept: 'text/vnd.turbo-stream.html',
      },
      method: this.data.get('method') || 'put',
    })
      .then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }
}
